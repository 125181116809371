export default {
  name: 'ForgotAccoutPage',
  data() {

    return {
      // 语言选项
      languageItemArr: [
        {
          label: 'English',
          value: "en"
        },
        {
          label: '简体中文',
          value: "zh"
        },
        {
          label: '繁體中文(港澳)',
          value: "zht"
        },
        {
          label: '繁體中文(台灣)',
          value: "tw"
        }
      ],
      stepNum: 1,
      imgcode: '',
      imgsrc: '',
      imgcodekey: '',
      imgsrc2: '',
      imgcodekey2: '',
      // 表单数据
      oneStepSubmitData: {
        name: '',
        user: '',
        img_code: '',
      },
      // 校验规则
      oneStepSubmitRules: {

      },
      iscode: true,
      time: 5,
      twoStepSubmitData: {
        areacode: '',
        phoneName: '',
        userphone: '',
        img_code2: ''
      },
      // 校验规则
      twoStepSubmitRules: {
        userphone: [
          { required: true, message: this.$t('forgotAccount.phoneNumError'), trigger: 'blur' },

        ],
        img_code2: [
          { required: true, message: this.$t('forgotAccount.correctImageCode'), trigger: 'blur' },
        ]
      },
      hrid: '',
      PEAuthPhoneMsgList: []
    }
  },
  created() {
    this.getImageCode()
  },
  computed: {
    phoneMsg() {
      return this.twoStepSubmitData.phoneName.replace(/(\d{2})\d{1,}(\d{2})/, '$1****$2');
    },
    showLanguage() {
      const currentLanguage = this.languageItemArr.find(item => item.value === this.$store.getters.lang)
      return currentLanguage.label
    },
    isNext() {
      if (this.oneStepSubmitData.name != '' && this.oneStepSubmitData.user != '' && this.oneStepSubmitData.img_code) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    getOfficialWebsite() {
      if (this.$store.getters.lang == 'zht') {
        window.open('https://www.313fm.com/tc/');
      } else if (this.$store.getters.lang == 'en') {
        window.open('https://www.313fm.com/en/');
      } else {
        window.open('https://www.313fm.com');
      }
    },
    getImageCode() {
      this.$http.post('/common/genimgcode', {}, { disabledAuth: true }).then((response) => {
        if (response.data.success) {
          this.imgcode = response.data.content.code;
          this.imgsrc = response.data.content.imagesrc;
          this.imgcodekey = response.data.content.key;
        } else {
          this.$alert(response.data.message, this.$t('common.alertPrompt'), {
            type: 'error',
            center: true
          })
        }
      })
    },
    getImageCode2() {
      this.$http.post('/common/genimgcode', {}, { disabledAuth: true }).then((response) => {
        if (response.data.success) {
          this.imgsrc2 = response.data.content.imagesrc;
          this.imgcodekey2 = response.data.content.key;
        } else {
          this.$alert(response.data.message, this.$t('common.alertPrompt'), {
            type: 'error',
            center: true
          })
        }
      })
    },
    // 切换语言
    switchLanguage(lang) {
      this.$store.commit('SET_LANG', lang)
      this.$i18n.locale = this.$store.getters.lang
      this.$pubsub.publish('langSwitchEvent');
    },
    //第一步
    pwdFirstAccount() {
      this.$refs['oneStepForm'].validate((valid) => {
        if (valid) {
          this.$http.post('/common/checkroleaccount', {
            "peab": this.oneStepSubmitData.name,
            "roleaccount": this.oneStepSubmitData.user,
            "code": this.oneStepSubmitData.img_code,
            key: this.imgcodekey,
            "langcode": this.$store.getters.lang.toUpperCase()
          }, { disabledAuth: true }).then((response) => {
            if (response.data.success) {
              this.stepNum = 2
              this.hrid = response.data.content.hrid
              this.twoStepSubmitData.areacode = response.data.content.areacode
              this.twoStepSubmitData.phoneName = response.data.content.ownerphone
              //! 后续新增功能 ——2021.5.7
              if (response.data.PEAuthPhoneList.length > 0) {
                //* 获取物业授权手机号
                let PEAuthPhoneList = []
                response.data.PEAuthPhoneList.forEach(item => {
                  let obj = {
                    description: item.description,
                    authphone: item.authphone.replace(/(\d{2})\d{1,}(\d{2})/, '$1****$2'),
                    value: item.authphone
                  }
                  PEAuthPhoneList.push(obj)
                })
                this.PEAuthPhoneMsgList = PEAuthPhoneList
                //* 更新需要确认的手机号为授权手机号数组的第一个
                this.twoStepSubmitData.phoneName = PEAuthPhoneList[0].value
              }
              if (response.data.content && response.data.content.ownerphone) {
                let obj = {
                  description: '',
                  authphone: response.data.content.ownerphone.replace(/(\d{2})\d{1,}(\d{2})/, '$1****$2'),
                  value: response.data.content.ownerphone
                }
                this.PEAuthPhoneMsgList.push(obj)
              }
              this.getImageCode2()
            } else {
              this.$alert(response.data.message, this.$t('common.alertPrompt'), {
                type: 'error',
                center: true
              })
            }
          });

        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    //第二步
    pwdgetAccount() {
      this.$refs['twoStepForm'].validate((valid) => {
        if (valid) {
          this.$http.post('/common/changepwd', {
            "areacode": this.twoStepSubmitData.areacode,
            "ownerphone": this.twoStepSubmitData.userphone,
            "secondownerphone": this.twoStepSubmitData.phoneName,
            "hrid": this.hrid,
            "langcode": this.$store.getters.lang.toUpperCase(),
            "imageCode": this.twoStepSubmitData.img_code2,
            "key": this.imgcodekey2,

          }, { disabledAuth: true }).then((response) => {
            if (response.data.success) {
              // this.hrid = response.data.content.hrid;
              // _this2.phonePage = true;
              // _this2.passwdPage = false;
              this.stepNum = 3
              //第三步
              let timer = setInterval(() => {
                this.time = this.time - 1;
                if (this.time === 0) {
                  clearInterval(timer);
                  this.$router.push("/login")
                }
              }, 1000);
            } else {
              this.$alert(response.data.message, this.$t('common.alertPrompt'), {
                type: 'error',
                center: true
              })
            }
          });

        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    jumpLogin() {
      this.$router.push("/login")
    }
  }
}