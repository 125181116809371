
export default {
	name: 'CommonReasonCreatePage',
	props: {
		editData: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			// 表单数据
			submitData: {
				description: '',
				type: ''
			},
			// 校验规则
			submitRules: {
				description: [
					{ required: true, message: this.$t('commonReason.commonReasonInputDescTip'), trigger: 'blur' },
					{ message: this.$t('commonReason.commonReasonDescOver100'), trigger: 'blur', max: 100 }
				],
				type: [
					{ required: true, message: this.$t('commonReason.commonReasonTypeSelectTip'), trigger: 'change' },
				]
			},
			reasonTypeList: []
		}
	},
	created() {
		if (this.editData) {

			this.submitData = JSON.parse(JSON.stringify(this.editData))
		}
		this.$nextTick(() => {
			this.getdatabind()
		})

	},
	methods: {
		getdatabind() {
			this.$http.post('/common/databind', {
				hrid: this.$store.getters.loginid,
				penum: this.$store.getters.penum,
				databindid: 'COMMREASONTYPE'
			}).then((response) => {
				if (response.data.success) {
					this.reasonTypeList = response.data.content;
				} else {
					this.$alert(response.data.message, this.$t('common.alertPrompt'), {
						type: 'error',
						center: true
					})
				}
			})
		},
		// 提交
		submitForm(formName) {
			if (this.editData) { 
				if (this.editData.description == this.submitData.description && this.editData.type == this.submitData.type) return this.$emit('createSuccess')
			}
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if (this.editData) {
						// 修改数据
						const requsetEditJson = {
							hrid: this.$store.getters.loginid,
							penum: this.$store.getters.penum,
							commonreasonid: this.submitData.commonreasonid,
							"description": this.submitData.description,
							"type": this.submitData.type,

						}
						this.$http.post('/v2/common/reason/edit', requsetEditJson).then(response => {
							if (response.data.success) {
								this.$message({
									type: 'success',
									message: this.$t('commonReason.editSuccess')
								});
								this.$emit('createSuccess')
							} else {
								this.$alert(response.data.message, this.$t('common.alertPrompt'), {
									type: 'error',
									center: true
								})
							}
						})
					} else {

						const requsetJson = {
							hrid: this.$store.getters.loginid,
							penum: this.$store.getters.penum,
							"description": this.submitData.description,
							"type": this.submitData.type,
						}
						this.$http.post('/v2/common/reason/add', requsetJson).then(response => {
							if (response.data.success) {

								this.$message({
									type: 'success',
									message: this.$t('commonReason.createSuccess')
								});
								this.$emit('createSuccess')
							} else {
								this.$alert(response.data.message, this.$t('common.alertPrompt'), {
									type: 'error',
									center: true
								})
							}
						})
					}

				} else {
					// console.log('error submit!!');
					return false;
				}
			});
		},
		// 关闭
		closeDialog() {
			this.$emit('closeDialog')
		},
	}
}