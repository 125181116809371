<template>
  <web-layout :title="$t('commonReason.commonReasonTitle')">
    <template>
      <el-row :gutter="20" class="auth-header">
        <el-col :span="7">
          <el-input :placeholder="$t('commonReason.commonReasonSearchTip')" :title="query ? '' : $t('commonReason.commonReasonSearchTip')" v-model="query" @keyup.enter.native="searchEvent" clearable>
            <el-button icon="el-icon-search" @click="searchEvent" slot="append"></el-button>
          </el-input>
        </el-col>
        <el-col :span="7">
          <el-select v-model="reasonType" @change="searchEvent" clearable :placeholder="$t('commonReason.commonReasonTypeSelectPlh')">
            <el-option v-for="(item, index) in reasonTypeList" :key="index" :label="item.description" :value="item.value"> </el-option>
          </el-select>
        </el-col>
        <el-col :span="9" :offset="1" style="text-align: right">
          <el-button @click="showSystemCommonDialog" type="primary">{{ $t('commonReason.commonReasonSystemTitle') }}</el-button>
          <el-button v-if="$store.getters.isOperate && !$store.getters.isDemo" @click="createAuthority" type="primary" icon="el-icon-add-solid">{{ $t('commonReason.commonReasonAdd') }}</el-button>
        </el-col>
      </el-row>
      <div ref="tableWrapper">
        <el-table ref="tableRef" :data="reasonList" style="width: 100%">
          <el-table-column prop="description" :label="$t('commonReason.commonReasonDesc')"> </el-table-column>

          <el-table-column prop="type" :formatter="formatterTypeDesc" :label="$t('commonReason.commonReasonType')"> </el-table-column>
          <el-table-column :label="$t('common.operation')" width="120">
            <template slot-scope="scope" v-if="$store.getters.isOperate && !$store.getters.isDemo">
              <el-tooltip effect="light" :content="$t('common.edit')" placement="top">
                <el-button @click="editClick(scope.row)" type="text" icon="el-icon-edit"></el-button>
              </el-tooltip>
              <el-tooltip effect="light" :content="$t('common.delete')" placement="top">
                <el-button @click="deleteClick(scope.row)" type="text" icon="el-icon-delete"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <div slot="empty">
            <el-empty :description="$t('commonReason.emptyTips')" :image="require('@/assets/empty-icon.svg')" />
          </div>
        </el-table>
        <pagination-box :total="pagesObj.total" :currentPage="pagesObj.page" :pageSize="pagesObj.pagesize" @current-change="getReasonList"></pagination-box>
      </div>
    </template>

    <el-dialog :title="editData ? $t('commonReason.commonReasonEdit') : $t('commonReason.commonReasonAdd')" :visible.sync="dialogVisible" destroy-on-close width="650px">
      <common-reason-create-page v-if="dialogVisible" :editData="editData" @closeDialog="closeDialog" @createSuccess="createSuccess"></common-reason-create-page>
    </el-dialog>

    <el-dialog :title="$t('commonReason.commonReasonSystemTitle')" :visible.sync="dialogSystemVisible" destroy-on-close width="550px">
      <div class="reason_box">
        <template v-if="systemCommonList.length > 0">
          <div class="reason_item" v-for="(item, index) in systemCommonList" :key="index">
            <div class="reason_num">{{ index + 1 }}</div>
            <div class="reason_desc">
              <span :title="item.description">
                {{ item.description }}
              </span>
            </div>
          </div>
        </template>

        <div class="empty_tip_box" v-else>
          <!-- <p>{{pageText.NODATA}}</p> -->
        </div>
      </div>
      <div class="reason_btn_box" style="margin-top: 25px; text-align: center">
        <el-button @click="closeSystemCommonDialog" type="primary">{{ $t('common.close') }}</el-button>
      </div>
    </el-dialog>
  </web-layout>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
