import CommonReasonCreatePage from '@/views/CommonReasonCreate/index.vue'

export default {
  name: 'CommonReasonPage',
  components: {
    CommonReasonCreatePage,
  },
  data() {
    return {
      // 常用理由列表数据
      reasonList: [],
      // 翻页配置
      pagesObj: {
        page: 1,
        pagesize: 10,
        total: 0
      },
      // 搜索条件
      query: '',
      // 理由类型
      reasonType: '',
      // 理由类型列表
      reasonTypeList: [],
      // 创建弹窗
      dialogVisible: false,
      // 编辑数据
      editData: null,
      // 系统默认理由弹窗
      dialogSystemVisible: false,
      systemCommonList: []
    }
  },
  mounted() {
    this.getdatabind('reasonTypeList', 'COMMREASONTYPE')
    this.getReasonList()
    this.$setLanguageChangeCallback(() => this.getdatabind('reasonTypeList', 'COMMREASONTYPE'))
  },
  methods: {
    getdatabind(key, value, callback) {
      this.$http.post('/common/databind', {
        hrid: this.$store.getters.loginid,
        penum: this.$store.getters.penum,
        databindid: value
      }).then((response) => {
        if (response.data.success) {
          this[key] = response.data.content;
          callback && callback()
        } else {
          this.$alert(response.data.message, this.$t('common.alertPrompt'), {
            type: 'error',
            center: true
          })
        }
      })
    },
    //* 获取常用理由列表
    getReasonList(page) {
      if (page) {
        this.pagesObj.page = page
      } else {
        this.pagesObj.page = 1
      }
      this.$http.post('/v2/common/reason/list', {
        hrid: this.$store.getters.loginid,
        penum: this.$store.getters.penum,
        "query": this.query,
        page: this.pagesObj.page,
        pagesize: this.pagesObj.pagesize,
        type: this.reasonType
      }, {
        loadTarget: this.$refs.tableWrapper
      }).then(response => {
        // 更新数据
        if (response.data.success) {
          this.reasonList = response.data.content.list;
          if (Array.isArray(this.reasonList) && !this.reasonList.length && this.pagesObj.page > 1) {
            this.getReasonList(this.pagesObj.page - 1)
            return
          }
          this.pagesObj = {
            ...this.pagesObj,
            total: response.data.content.page.total
          }

        } else {
          this.$alert(response.data.message, this.$t('common.alertPrompt'), {
            type: 'error',
            center: true
          })
        }
      })
    },
    // 删除
    deleteClick(row) {
      this.$utils.blurFn()
      this.$confirm(this.$t('securityRules.confirmDelete'), this.$t('common.alertPrompt'), {
        type: 'warning',
        center: true
      }).then(() => {

        const requsetJson = {
          hrid: this.$store.getters.loginid,
          penum: this.$store.getters.penum,
          commonreasonid: row.commonreasonid
        }
        this.$http.post('/v2/common/reason/delete', requsetJson).then(response => {
          if (response.data.success) {
            this.$message({
              type: 'success',
              message: this.$t('commonReason.deleteSuccess')
            });
            // 更新组列表
            this.getReasonList(this.pagesObj.page);
          } else {
            this.$alert(response.data.message, this.$t('common.alertPrompt'), {
              type: 'error',
              center: true
            })
          }
        })
      }).catch(() => {

      });
    },
    // 创建
    createAuthority() {
      this.editData = null
      this.dialogVisible = true
    },
    // 创建或修改成功
    createSuccess() {
      this.dialogVisible = false
      this.getReasonList()
    },
    // 关闭弹窗
    closeDialog() {
      this.dialogVisible = false
    },
    // 修改
    editClick(item) {
      this.editData = item
      this.dialogVisible = true

    },
    // 搜索
    searchEvent() {
      this.getReasonList()
    },
    // 格式化类型描述
    formatterTypeDesc(val) {
      if (val.type) {
        let item = this.reasonTypeList.find(item => item.value == val.type)
        return item ? item.description : ''
      } else {
        return ''
      }
    },
    // 显示系统默认
    showSystemCommonDialog() {
      this.getdatabind('systemCommonList', 'SYSCOMMREASON', () => {
        this.dialogSystemVisible = true
      })
    },
    closeSystemCommonDialog() {
      this.dialogSystemVisible = false
    },
  }
}