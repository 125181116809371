<template>
  <div>
    <el-container class="container">
      <el-main class="main-wrapper">
        <el-form label-width="120px" :model="submitData" :rules="submitRules" ref="createForm">
          <!--  理由描述  -->
          <el-form-item :label="$t('commonReason.commonReasonDesc')" prop="description">
            <el-input v-model="submitData.description"></el-input>
          </el-form-item>
          <el-form-item :label="$t('commonReason.commonReasonType')" prop="type">
            <el-select style="width: 100%" v-model="submitData.type" :placeholder="$t('commonReason.commonReasonTypeSelectPlh')">
              <el-option v-for="(item, index) in reasonTypeList" :key="index" :label="item.description" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-main>
      <el-footer class="footer-wrapper" height="auto">
        <el-button @click="closeDialog">{{ $t('common.cancel') }}</el-button>
        <el-button type="primary" @click="submitForm('createForm')">{{ $t('common.save') }}</el-button>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
